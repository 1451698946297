.Navigation {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.15);
  padding: 1rem;
}

.Navigation ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 800px;
  width: 100%;
}

.Navigation li {
  margin: 0;
  padding: 0;
}

.Navigation a {
  font-size: 1.2rem;
  color: #000000;
  text-decoration: none;
  padding: 1rem;
  transition: color 0.2s ease;
}

.Navigation a:hover {
  color: #283593;
}
