#tutor-name {
  font-family: "Architects Daughter", cursive;
  font-size: 32px;
  margin-top: 5%;
  margin-bottom: 2%;
}

#session-card {
  min-width: 1113px;
  max-width: 1113px;
  width: 1113px;
  margin: 0 auto;
  position: relative;
}
