.interaction-area {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.whiteboard {
  position: relative;
  margin: 0 auto;
  width: 800px;
}
