.registration-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.registration-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.wrapper-form {
  position: relative;
}

.wrapper-form .form-icon {
  position: absolute;
  left: 10px;
}

.form-control {
  padding-left: 10px;
  font-size: 16px;
}

.simple-select {
    width : 50%;
  border-radius: 5px;
  border: none;
  background-color: #f0f0f0;
}

#submit-button {
  width: auto;
  height: auto;
  font-weight: bold;
  color : white;
  white-space: nowrap;
  background-color: #283593;
  text-transform : none;
  opacity : 90%;
}

#submit-button:hover {
  background-color: #283593;
  opacity: 100%;
}
