.canvas-container {
  display: block;
  position: relative;
}

.tool-container {
  display: flex;
  justify-content: space-around;
  height: 40px;
}

#canvas_3_ID {
  position: absolute;
  z-index: 3;
}

#overlay {
  z-index: 2;
  position: absolute;
}

#images {
  position: absolute;
  z-index: 1;
}

#remoteCanvas {
  position: absolute;
  z-index: 1;
}

canvas {
  border: 1px solid #000000;
}

.all-images {
  margin-top: 50%;
  position: relative;
}
.lesson-container {
  display: flex;
  flex-direction: row;
}
.slide-popover {
  position: absolute;
}
.slides-bar {
  display: block;
  margin: 0 auto;
}

.slides {
  height: 10px;
  width: 10px;
  position: absolute;
}

#textbox {
  z-index: 3;
  background: transparent;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  line-height: 30px;
  font-size: 20px;
}

.active {
  background-color: blue;
}

.slides-container {
  display: flex;
  flex-direction: row;
  padding: 10px 10px;
  justify-content: space-evenly;
}

input {
  z-index: 3;
  background: transparent;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  line-height: 20px;
  font-size: 20px;
}
